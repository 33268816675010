import React from 'react';

const NavBar = () => {
    return (
        <nav className='flex justify-between items-center w-full bg-black text-white p-5'>
            <h1 className='text-3xl font-bungee'>Viper Digital</h1>
            <ul className='hidden md:flex space-x-5'>
                <li>
                    <button>
                        <p>Home</p>
                    </button>
                </li>
                <li>
                    <button>
                        <p>About</p>
                    </button>
                </li>
                <li>
                    <button>
                        <p>Services</p>
                    </button>
                </li>
                <li>
                    <button>
                        <p>Contact</p>
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar;