import React from 'react';
import '../index.css';

const Card = ( props ) => {
    
    const { title, description } = props;

    return (
        <div className={`col-span-12 md:col-span-4 h-full bg-gradient-to-b to-[#915EFF] from-green-500 p-[1px] rounded-xl shadow-md`}>
            <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-xl bg-slate-950' {...props}>
                <h3 className='text-2xl font-bold pb-2 md:pb-8'>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default Card;