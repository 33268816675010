import react from 'react';
import Home from './Home';
import NavBar from './Components/NavBar';

function App() {
  return (
    <div className="bg-slate-950">
      <NavBar />
      <Home />
    </div>
  );
}

export default App;
