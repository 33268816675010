import React from 'react';
import './index.css';
// import './index2.css';
import logo from './assets/images/herobg-ecbfddc8.png';
import Card from './Components/Card';
import TypingAnimation from './Components/TypingAnimation';
import { TypeAnimation } from 'react-type-animation';

import { PiNumberCircleOneBold } from "react-icons/pi";
import { PiNumberCircleTwoBold } from "react-icons/pi";

const Home = () => {      

    return (
        <div className='relativeflex flex-col w-full min-h-dvh items-center py-12 md:py-24 md:px-20 text-green-500 main-bg'>
            {/* <img className='absolute top-0 object-cover md:h-auto md:w-full' src={logo} alt='mainBg' /> */}
            <div className='bg-opacity-75 z-1 bg-transparent min-h-screen md:w-full border-slate-700 rounded-xl'>
            <section className='flex items-start md:items-stretch'>
                <div className="flex flex-col justify-center items-center mt-5 mr-3 md:mr-5">
                    <div className="w-5 h-5 rounded-full bg-[#915EFF]"></div>
                    <div className="w-1 h-80 fade-vertical-line"></div>
                </div>
                <div className='flex flex-col pt-10 md:pt-16'>
                    <h1 className='font-bungee text-4xl md:text-9xl tracking-widest text-green-500 '>Welcome to <span className='block'>Viper Digital</span></h1>
                    <div className='flex flex-col my-10'>
                        <h2 className='text-3xl md:text-4xl'>Empowering Businesses with Innovative Web & Mobile App Solutions</h2>
                        <p className='text-xl md:text-2xl text-[#915EFF]'>We Offer 
                        <TypeAnimation
                            sequence={[
                                ' Expert development...',
                                2000,
                                ' Technology consulting...',
                                2000,
                                ' Mobile platform...',
                                2000,
                            ]}
                            wrapper="span"
                            speed={200}
                            deleteSpeed={50}
                            pauseSpeed={2000}
                            repeat={Infinity}
                            className="text-[#FFFFFF] font-bold"
                            // style={{{ fontSize: '2em', display: 'inline-block', color: 'white' }}}
                        /> </p>
                        {/* <p className='tracking-widest'>Expert development, technology consulting, and a groundbreaking mobile platform.</p> */}
                    </div>
                {/* <button onClick={() => {handleClick()}}>Click me</button> */}
                </div>
            </section>
            <div className='flex flex-col px-2 md:px-10'>
                <h2>Our Expertise</h2>
                <hr className='w-full h-1 bg-[#915EFF] border border-[#915EFF] rounded-sm'/>
                <div className='md:w-1/2'>
                    <h3 className='font-bold text-xl text-slate-500'>
                        With a passion for innovation and a customer-first mindset, we take pride in delivering high-quality results. Our team stays on the pulse of industry trends and best practices, ensuring that every project exceeds expectations and drives success.
                    </h3>
                </div>
                <div className='grid grid-cols-12 gap-6 items-center my-10'>
                    <Card title='Web Site Development' description='Let us create a stunning website that showcases your brand and drives conversions. Our expert team of developers will bring your vision to life.' />
                    <Card title='Web and Mobile App Development' description='Build custom, scalable web and mobile apps designed to meet your business goals.' />
                    <Card title='Technology Consulting' description='We help businesses transform with expert guidance on technology strategy, implementation, and optimization.' />
                </div>
            {/* </div> */}
                {/* <div>
                    <h3>Web Site Development</h3>
                    <p>Let us create a stunning website that showcases your brand and drives conversions. Our expert team of developers will bring your vision to life.</p>
                </div>
                <div>
                    <h3>Web and Mobile App Development</h3>
                    <p>Build custom, scalable web and mobile apps designed to meet your business goals.</p>
                </div>
                <div>
                    <h3>Technology Consulting</h3>
                    <p>We help businesses transform with expert guidance on technology strategy, implementation, and optimization.</p>
                </div> */}
            </div>
            <div className='relative flex flex-col w-full h-auto px-2 md:px-10 '>
                <h2>Our Process</h2>
                <hr className='w-full h-1 bg-[#915EFF] border border-[#915EFF] rounded-sm'/>
                <div className='md:w-3/4'>
                    <h3 className='font-bold text-xl text-slate-500'>
                    Our proven process ensures that we deliver solutions that are not only aligned with your goals but also built for long-term success. Each step is designed to maximize efficiency, minimize risks, and ensure a seamless transition from concept to reality.
                    </h3>
                </div>
                <section className="sm:px-16 px-6 sm:py-16 py-10 max-w-7xl mx-auto relative z-0">
                    <div className='timeline-container'>
                            <div className='timeline-line' />
                            <div className='timeline-element left bg-gradient-to-tr to-[#915EFF] from-green-500'>
                                <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-2xl bg-slate-950'>
                                    <h3 className='font-proponoBc text-2xl text-[#915EFF]'>Step <span className='text-white'>1</span><br/><span className=''>Discovery & Strategy</span></h3>
                                    <p>We start by understanding your business goals, challenges, and vision. Through in-depth discussions and research, we identify the best opportunities and tailor a strategy that aligns with your objectives.</p>
                                </div>
                            </div>
                            <div className='timeline-element right bg-gradient-to-tr to-[#915EFF] from-green-500'>
                                <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-2xl bg-slate-950'>
                                    <h3 className='font-proponoBc text-2xl text-[#915EFF]'>Step <span className='text-white'>2</span><br/><span className=''>Planning & Design</span></h3>
                                    <p>With a clear strategy in place, we move on to planning. Our team designs intuitive, user-focused interfaces and maps out functionality, ensuring every element meets your needs while providing a seamless user experience.</p>
                                </div>
                            </div>
                            <div className='timeline-element left bg-gradient-to-tr to-[#915EFF] from-green-500'>
                                <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-2xl bg-slate-950'>
                                    <h3 className='font-proponoBc text-2xl text-[#915EFF]'>Step <span className='text-white'>3</span><br/><span className=''>Development & Integration</span></h3>
                                    <p>Our expert developers bring your vision to life, building secure, scalable web and mobile applications. We focus on clean code, best practices, and integrating any existing systems to ensure your digital solution works flawlessly.</p>
                                </div>
                            </div>
                            <div className='timeline-element right bg-gradient-to-tr to-[#915EFF] from-green-500'>
                                <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-2xl bg-slate-950'>
                                    <h3 className='font-proponoBc text-2xl text-[#915EFF]'>Step <span className='text-white'>4</span><br/><span className=''>Testing & Quality Assurance</span></h3>
                                    <p>Before launching, we thoroughly test every aspect of the product to ensure it performs as expected. From functionality and performance to security and user experience, we leave no stone unturned to deliver a high-quality solution.</p>
                                </div>
                            </div>
                            <div className='timeline-element left bg-gradient-to-tr to-[#915EFF] from-green-500'>
                                <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-2xl bg-slate-950'>
                                    <h3 className='font-proponoBc text-2xl text-[#915EFF]'>Step <span className='text-white'>5</span><br/><span className=''>Launch & Support</span></h3>
                                    <p>Once everything is perfected, we launch your app or website. Our commitment doesn’t stop there—we provide ongoing support, ensuring smooth operation, addressing any issues, and offering updates to keep your platform optimized and secure.</p>
                                </div>
                            </div>
                        </div>
                {/* <div className="mt-20 flex flex-col">
                <div className="vertical-timeline vertical-timeline--animate vertical-timeline--two-columns">
                <div id="" className="vertical-timeline-element">
                <span className="vertical-timeline-element-icon bounce-in" style={{background: "rgb(255, 255, 255)"}}>
                    <div className="flex justify-center items-center w-full h-full">
                </div>
                </span>
                <div className="vertical-timeline-element-content bounce-in" style={{background: "rgb(29, 24, 54)", color: "rgb(255, 255, 255)"}}>
                    <div className="vertical-timeline-element-content-arrow" style={{borderRight: "7px solid rgb(35, 38, 49);"}}>
                        </div>
                        <div>
                            <h3 className="text-white text-[24px] font-bold">Honours Computer Science (H. BSc)</h3>
                            <p className="text-secondary text-[16px] font-semibold" style={{margin: "0px"}}>Ontario Tech University, Oshawa, ON</p>
                            </div><ul className="mt-5 list-disc ml-5 space-y-2">
                                <li className="text-white-100 text-[14px] pl-1 tracking-wider">Courses undertaken: Data Structures and Algorithms, Object-Oriented Programming, REST API Development and Integration, Software Design UML, Python Data Analysis, Discrete Mathematics, Computer Architecture, Operating Systems, Database Systems, Software Engineering.</li>
                                </ul><span className="vertical-timeline-element-date">2022 - Present</span>
                                </div></div>
                                <div id="" className="vertical-timeline-element">
                                    <span className="vertical-timeline-element-icon bounce-in" style={{background: "rgb(255, 255, 255)"}}>
                                        <div className="flex justify-center items-center w-full h-full">
                                            {/* <img src="/assets/rhhs-e7215809.jpg" alt="Richmond Hill High School, ON" className="w-[60%] h-[60%] object-contain"> 
                                            </div>
                                            </span>
                                            <div className="vertical-timeline-element-content bounce-in" style={{background: "rgb(29, 24, 54)", color: "rgb(255, 255, 255);"}}>
                                                <div className="vertical-timeline-element-content-arrow" style={{borderRight: "7px solid rgb(35, 38, 49)"}}>
                                                    </div>
                                                    <div>
                                                        <h3 className="text-white text-[24px] font-bold">High School</h3>
                                                        <p className="text-secondary text-[16px] font-semibold" style={{margin: "0px"}}>Richmond Hill High School, ON</p>
                                                        </div>
                                                        <ul className="mt-5 list-disc ml-5 space-y-2">
                                                            <li className="text-white-100 text-[14px] pl-1 tracking-wider">Achievements: Valedictorian, Ontario Scholar</li>
                                                            <li className="text-white-100 text-[14px] pl-1 tracking-wider">Percentage: 96%</li>
                                                            </ul>
                                                            <span className="vertical-timeline-element-date">2018-2022</span>
                                                            </div>
                                                            </div>
                                                            </div>
                                                            </div> */}
                                                            </section>
            </div>
            <div className='flex flex-col px-2 md:px-10'>
                <h2>Our Team</h2>
                <hr className='w-full h-1 bg-[#915EFF] border border-[#915EFF] rounded-sm'/>
                <div className='md:w-1/2'>
                    <h3 className='font-bold text-xl text-slate-500'>
                    Our team is made up of passionate professionals who are dedicated to helping businesses succeed. With a diverse skill set and a customer-first mindset, we work together to deliver high-quality results that drive growth and innovation.
                    </h3>
                </div>
                <div>
                    <h3>John Doe</h3>
                    <p>John is an expert web developer with over 10 years of experience.</p>
                </div>
                <div>
                    <h3>Jane Doe</h3>
                    <p>Jane is a talented designer with a passion for creating beautiful websites.</p>
                </div>
                <div>
                    <h3>Joe Doe</h3>
                    <p>Joe is a technology consultant with a proven track record of helping businesses succeed.</p>
                </div>
            </div>
            <div className='flex flex-col px-2 md:px-10'>
                <h2>Featured Projects</h2>
                <hr className='w-full h-1 bg-[#915EFF] border border-[#915EFF] rounded-sm'/>
                <div className='grid grid-cols-12 gap-6 items-center my-10'>
                    <div className={`col-span-${12} md:col-span-${6} h-full bg-gradient-to-b to-[#915EFF] from-green-500 p-[1px] rounded-xl shadow-md`}>
                        <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-xl bg-slate-950'>
                            <h3 className='text-2xl font-bold pb-2 md:pb-8'>{'title'}</h3>
                            <iframe src="https://quizm3.com/" title="title" className='w-full h-96'/>
                        </div>
                    </div>
                    <div className={`col-span-${12} md:col-span-${6} h-full bg-gradient-to-b to-[#915EFF] from-green-500 p-[1px] rounded-xl shadow-md`}>
                        <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-xl bg-slate-950'>
                            <h3 className='text-2xl font-bold pb-2 md:pb-8'>{'title'}</h3>
                            <iframe src="https://jtm-fitness.web.app/" title="title" className='w-full h-96'/>
                        </div>
                    </div>
                    <div className={`col-span-${12} md:col-span-${6} h-full bg-gradient-to-b to-[#915EFF] from-green-500 p-[1px] rounded-xl shadow-md`}>
                        <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-xl bg-slate-950'>
                            <h3 className='text-2xl font-bold pb-2 md:pb-8'>{'title'}</h3>
                            <iframe src="https://zia-swga.firebaseapp.com/" title="title" className='w-full h-96'/>
                        </div>
                    </div>
                    <div className={`col-span-${12} md:col-span-${6} h-full bg-gradient-to-b to-[#915EFF] from-green-500 p-[1px] rounded-xl shadow-md`}>
                        <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-xl bg-slate-950'>
                            <h3 className='text-2xl font-bold pb-2 md:pb-8'>{'title'}</h3>
                            <iframe src="https://community-development-system.firebaseapp.com/" title="title" className='w-full h-96 bg-white'/>
                        </div>
                    </div>
                    <div className={`col-span-${12} md:col-span-${6} h-full bg-gradient-to-b to-[#915EFF] from-green-500 p-[1px] rounded-xl shadow-md`}>
                        <div className='flex flex-col p-5 md:pt-5 md:px-3 h-full rounded-xl bg-slate-950'>
                            <h3 className='text-2xl font-bold pb-2 md:pb-8'>{'title'}</h3>
                            <iframe src="https://atl-movie-merch.firebaseapp.com/" title="title" className='w-full h-96'/>
                        </div>
                    </div>
                    <div className={`col-span-${12} md:col-span-${6} h-full bg-gradient-to-b to-[#915EFF] from-green-500 p-[1px] rounded-xl shadow-md`}>
                        <div className='flex flex-col p-5 md:pt-5 md:px- h-full rounded-xl bg-slate-950'>
                            <h3 className='text-2xl font-bold pb-2 md:pb-8'>{'title'}</h3>
                            <iframe src="https://prodigy-mas.firebaseapp.com/" title="title" className='w-[full] h-96'/>
                        </div>
                    </div>
                    {/* <Card medSpan={6} className='flex flex-col items-center p-10 col-span-12 md:col-span-6 bg-black border border-slate-600 h-full rounded-xl' title='Project 1' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'/>
                    <Card medSpan={6} className='flex flex-col items-center p-10 col-span-12 md:col-span-6 bg-black border border-slate-600 h-full rounded-xl' title='Project 2' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'/>
                    <Card medSpan={6} className='flex flex-col items-center p-10 col-span-12 md:col-span-6 bg-black border border-slate-600 h-full rounded-xl' title='Project 3' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'/>
                    <Card medSpan={6} className='flex flex-col items-center p-10 col-span-12 md:col-span-6 bg-black border border-slate-600 h-full rounded-xl' title='Project 4' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'/>
                    <Card medSpan={6} className='flex flex-col items-center p-10 col-span-12 md:col-span-6 bg-black border border-slate-600 h-full rounded-xl' title='Project 3' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'/>
                    <Card medSpan={6} className='flex flex-col items-center p-10 col-span-12 md:col-span-6 bg-black border border-slate-600 h-full rounded-xl' title='Project 4' description='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'/> */}
                </div>
            </div>
        </div>
    </div>
    );
}


export default Home;